import { Box, Card, CardContent, Divider, Typography } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAccount } from "../../account/data/account.api";
import { RootState } from "../../../store";
import moneyParser from "../../../shared/moneyParser";
import { getReport } from "../data/report.api";
import moment from "moment";

export default function Profit(props: {
    date_start: string,
    date_end: string
}) {

    const dispatch = useDispatch<any>();

    const { accountList } = useSelector((state: RootState) => state.account)
    const { reportList } = useSelector((state: RootState) => state.report)

    useEffect(() => {
        dispatch(getAccount())
        dispatch(getReport())
    }, [dispatch])

    const momentDateStart = moment(props.date_start)
    const momentDateEnd = moment(props.date_end)
    const reportListFiltered = reportList.filter(report => moment(report.ts_created).isBetween(momentDateStart, momentDateEnd))
    const totalSpendCredit = reportListFiltered.filter(report => report.account_source.parrent_id === 4).reduce((total, r) => total + r.value, 0)
    const totalSpendDebet = reportListFiltered.filter(report => report.account.parrent_id === 4).reduce((total, r) => total + r.value, 0)
    const totalSpend = totalSpendCredit - totalSpendDebet 
    const totalIncomeCredit = reportListFiltered.filter(report => report.account_source.parrent_id === 3).reduce((total, r) => total + r.value, 0)
    const totalIncomeDebet = reportListFiltered.filter(report => report.account.parrent_id === 3).reduce((total, r) => total + r.value, 0)
    const totalIncome = totalIncomeCredit - totalIncomeDebet

    const accountListFiltered = accountList.filter(account => [3, 4].includes(account.id) || (account.parrent_id !== null && [3, 4].includes(account.parrent_id)))

    const renderItem = (parrent: null | number) => {
        return accountListFiltered.filter(account => account.parrent_id === parrent).map(account => {
            let totalCredit = 0
            let totalDebet = 0
            if (account.is_head) {
                totalCredit = reportListFiltered.filter(report => report.account_source.parrent_id === account.id).reduce((total, r) => total + r.value, 0)
                totalDebet = reportListFiltered.filter(report => report.account.parrent_id === account.id).reduce((total, r) => total + r.value, 0)
            }
            else {
                totalCredit = reportListFiltered.filter(report => report.account_source.id === account.id).reduce((total, r) => total + r.value, 0)
                totalDebet = reportListFiltered.filter(report => report.account.id === account.id).reduce((total, r) => total + r.value, 0)
            }
            return (
                <Box
                    key={account.id}
                    marginLeft={3}
                >
                    <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        padding={1}
                        sx={{
                            ":hover": {
                                bgcolor: "primary.main"
                            }
                        }}
                    >
                        <Typography>[{account.code}] {account.name}</Typography>
                        <Typography fontWeight={account.is_head ? "bold" : undefined}>{moneyParser(totalCredit - totalDebet)}</Typography>
                    </Box>
                    {renderItem(account.id)}
                </Box>
            )
        })
    }

    return (
        <Card>
            <CardContent>
                {renderItem(null)}
                <Divider sx={{ marginY: 2 }} />
                <Box display={"flex"} justifyContent={"space-between"} padding={1}>
                    <Typography fontWeight={"bold"}>Total Laba Rugi</Typography>
                    <Typography fontWeight={"bold"}>{moneyParser(totalIncome + totalSpend)}</Typography>
                </Box>
            </CardContent>
        </Card>
    )
}